<template>
    <div>
        <layout-full-width :title="$t('pick-lists')">
            <div class="row">
                <div class="col-12">
                </div>
            </div>

        </layout-full-width>
    </div>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PagePickLists",
    components: { LayoutFullWidth }
}
</script>

<style scoped lang="scss"></style>